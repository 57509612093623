"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var js_1 = require("@samsa/samsa-api/js");
var react_i18next_1 = require("react-i18next");
var react_1 = require("react");
var useOnChange_1 = require("../../hook/useOnChange");
var TransportationVisitorTax = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var _a = tslib_1.__read((0, react_1.useState)([]), 2), providers = _a[0], setProviders = _a[1];
    var selectedProviderId = (0, useOnChange_1.useOnChange)(null);
    var _b = tslib_1.__read(aurum_1.React.useState(new Date().getFullYear()), 2), year = _b[0], setYear = _b[1];
    var _c = tslib_1.__read(aurum_1.React.useState(new Date().getMonth() + 1), 2), month = _c[0], setMonth = _c[1];
    aurum_1.React.useEffect(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var r;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, js_1.FeatureProvider.getAll({ size: 0 })];
                    case 1:
                        r = _a.sent();
                        setProviders(r.content);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    var _d = tslib_1.__read(aurum_1.React.useState(null), 2), data = _d[0], setData = _d[1];
    aurum_1.React.useEffect(function () {
        setData(null);
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var _a;
            var _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = setData;
                        return [4 /*yield*/, js_1.VisitorTax.statistic({
                                providerId: (_b = selectedProviderId.value) !== null && _b !== void 0 ? _b : undefined,
                                year: year,
                                month: month
                            })];
                    case 1:
                        _a.apply(void 0, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [selectedProviderId.value, year, month]);
    return aurum_1.React.createElement(aurum_1.Sheet, { className: 'tile size-1-2' },
        aurum_1.React.createElement(aurum_1.Header, null,
            aurum_1.React.createElement("h3", null, "Personenbef\u00F6rderungen mit G\u00E4stebeitrag")),
        aurum_1.React.createElement(aurum_1.Body, null, data
            ? aurum_1.React.createElement(aurum_1.React.Fragment, null,
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("h1", { style: { textAlign: 'right', width: '160px' } }, data.total),
                    aurum_1.React.createElement("h1", { style: { textAlign: 'right' } }, "100%")),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("div", { style: { textAlign: 'right' } }, "gesamt")),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("h1", { style: { textAlign: 'right', width: '160px' } }, data.withvt),
                    aurum_1.React.createElement("h1", { style: { textAlign: 'right' } },
                        Math.round(100 / data.total * data.withvt),
                        "%")),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("div", { style: { textAlign: 'right' } }, "mit G\u00E4stebeitrag")),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("h1", { style: { textAlign: 'right', width: '160px' } }, data.total - data.withvt),
                    aurum_1.React.createElement("h1", { style: { textAlign: 'right' } },
                        Math.round(100 / data.total * (data.total - data.withvt)),
                        "%")),
                aurum_1.React.createElement(aurum_1.Row, null,
                    aurum_1.React.createElement("div", { style: { textAlign: 'right' } }, "ohne G\u00E4stebeitrag")))
            : aurum_1.React.createElement(aurum_1.Progress, null)),
        aurum_1.React.createElement(aurum_1.Footer, null,
            aurum_1.React.createElement(aurum_1.Row, null,
                aurum_1.React.createElement(aurum_1.Select, { label: t('Entity.FeatureProvider'), value: selectedProviderId.inputvalue, native: false, options: providers.map(function (value) {
                        return aurum_1.React.createElement("option", { key: value.id, value: value.id, label: value.toString() });
                    }), onChange: selectedProviderId.onChange })),
            aurum_1.React.createElement(aurum_1.Row, null,
                aurum_1.React.createElement(aurum_1.Select, { label: 'Jahr', value: year, onChange: function (e) {
                        setYear(parseFloat(e.target.value));
                    }, options: [2024, 2025].map(function (value) { return ({ value: value }); }) }),
                aurum_1.React.createElement(aurum_1.Select, { label: 'Monat', value: month, onChange: function (e) {
                        setMonth(parseFloat(e.target.value));
                    }, options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(function (value) { return ({ value: value }); }) }))));
};
exports.default = aurum_1.React.memo(TransportationVisitorTax);
