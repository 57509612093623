"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var js_1 = require("@samsa/samsa-api/js");
var aurum_1 = require("@mdsi/aurum");
var react_i18next_1 = require("react-i18next");
var useOnChange_1 = require("../hook/useOnChange");
var OfcEntry = /** @class */ (function () {
    function OfcEntry(featureName, ownerFeatureCondition) {
        this.featureName = featureName;
        this.ownerFeatureCondition = ownerFeatureCondition;
    }
    return OfcEntry;
}());
// Object.keys(obj) => [key1, key2, key3];
// Object.values(obj) => [value1, value2, value3];
// Object.entries(obj) => [[key1, value1], [key2, value2], [key3, value3]];
var OwnerConditionMerchant = function (_a) {
    var _b, _c;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _d = tslib_1.__read((0, react_1.useState)(null), 2), owners = _d[0], setOwners = _d[1];
    var _e = tslib_1.__read((0, react_1.useState)(''), 2), selectedOwner = _e[0], setSelectedOwner = _e[1];
    var _f = tslib_1.__read((0, react_1.useState)(false), 2), loadingOwner = _f[0], setLoadingOwner = _f[1];
    var _g = tslib_1.__read((0, react_1.useState)(null), 2), ownerCondition = _g[0], setOwnerCondition = _g[1];
    var _h = tslib_1.__read((0, react_1.useState)(false), 2), showModalOwnerCondition = _h[0], setShowModalOwnerCondition = _h[1];
    var _j = tslib_1.__read((0, react_1.useState)(false), 2), loadingOwnerConditions = _j[0], setLoadingOwnerCondition = _j[1];
    var _k = tslib_1.__read((0, react_1.useState)(null), 2), commissionFeeCalculations = _k[0], setCommissionFeeCalculations = _k[1];
    // const [inputPhone, setPhone] = useState<string>('');
    var _l = tslib_1.__read((0, react_1.useState)(false), 2), loadingFeatureByOwner = _l[0], setLoadingFeatureByOwner = _l[1];
    var _m = tslib_1.__read((0, react_1.useState)(null), 2), ownerFeatureConditions = _m[0], setOwnerFeatureConditions = _m[1];
    var _o = tslib_1.__read((0, react_1.useState)(null), 2), actionLabel = _o[0], setActionLabel = _o[1];
    var _p = tslib_1.__read((0, react_1.useState)(false), 2), saveBtnDisabled = _p[0], setSaveBtnDisabled = _p[1];
    var _q = tslib_1.__read((0, react_1.useState)(null), 2), modalPhoneData = _q[0], setModalPhoneData = _q[1];
    var noPaymentSplitFeeTax = (0, useOnChange_1.useOnChange)(false);
    var paymentSplitFeeAlreadyContainsTaxes = (0, useOnChange_1.useOnChange)(false);
    var ladeMeineOwners = (0, react_1.useCallback)(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var r, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoadingOwner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, js_1.Owner.getAll({ size: 0 })];
                    case 2:
                        r = _a.sent();
                        setOwners(r.content);
                        if (r.content.length >= 1) {
                            setSelectedOwner(r.content[0].id);
                            loadFeatureForOwner(r.content[0].id);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        failCondition();
                        return [3 /*break*/, 4];
                    case 4:
                        setLoadingOwner(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    // todo Hounsing > Feature
    var loadFeatureForOwner = (0, react_1.useCallback)(function (owner) {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var features_1, featureIds, featureConditions_1, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoadingFeatureByOwner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, (0, js_1.getAllFeaturesForOwner)(owner)];
                    case 2:
                        features_1 = _a.sent();
                        featureIds = Object.keys(features_1);
                        featureConditions_1 = new Map();
                        featureIds === null || featureIds === void 0 ? void 0 : featureIds.forEach(function (featureId) {
                            var _a, _b, _c, _d;
                            var locallyModifiedOfcEntry = ownerFeatureConditions ? ownerFeatureConditions[featureId] : null;
                            var originalFeatureCondition = (_a = ownerCondition === null || ownerCondition === void 0 ? void 0 : ownerCondition.featureConditions) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.featureId === featureId; });
                            var commissionFee = (_c = (_b = locallyModifiedOfcEntry === null || locallyModifiedOfcEntry === void 0 ? void 0 : locallyModifiedOfcEntry.ownerFeatureCondition) === null || _b === void 0 ? void 0 : _b.commissionFee) !== null && _c !== void 0 ? _c : originalFeatureCondition === null || originalFeatureCondition === void 0 ? void 0 : originalFeatureCondition.commissionFee;
                            featureConditions_1[featureId] = new OfcEntry(features_1[featureId], new js_1.OwnerFeatureCondition(tslib_1.__assign(tslib_1.__assign({}, (_d = locallyModifiedOfcEntry === null || locallyModifiedOfcEntry === void 0 ? void 0 : locallyModifiedOfcEntry.ownerFeatureCondition) !== null && _d !== void 0 ? _d : {}), { featureId: featureId, commissionFee: commissionFee })));
                        });
                        setOwnerFeatureConditions(featureConditions_1);
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        failCondition();
                        return [3 /*break*/, 4];
                    case 4:
                        setLoadingFeatureByOwner(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [ownerCondition]);
    // lädt die passende Ownercondition
    var loadOwnerCondition = (0, react_1.useCallback)(function (ownerID) {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var condition, e_3;
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setLoadingOwnerCondition(true);
                        setActionLabel(null);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, js_1.OwnerCondition.getAll({ 'owner.id': ownerID })];
                    case 2:
                        condition = _b.sent();
                        setOwnerCondition((_a = condition === null || condition === void 0 ? void 0 : condition.content) === null || _a === void 0 ? void 0 : _a[0]);
                        setCommissionFeeCalculations([
                            js_1.OwnerCondition.enum.commissionFeeCalculation.GROSS,
                            js_1.OwnerCondition.enum.commissionFeeCalculation.NET
                        ]);
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _b.sent();
                        console.error(e_3);
                        failCondition();
                        return [3 /*break*/, 4];
                    case 4:
                        setLoadingOwnerCondition(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    // wird initial mindestens einmal beim laden der Seite aufgerufen
    (0, react_1.useEffect)(ladeMeineOwners, []);
    // immer wenn sich was an den Dependencies ändert, wird meine Methode
    // loadOwnerCondition aufgerufen
    // lädt die OwnerConditions jedesmal, wenn sich der selectedOwner ändert
    (0, react_1.useEffect)(function () {
        loadOwnerCondition(selectedOwner);
    }, [selectedOwner]);
    var showOwnerFeatureConditions = function () {
        var _a, _b, _c;
        return ((_a = Object.entries(ownerFeatureConditions)) === null || _a === void 0 ? void 0 : _a.length) != 0
            ? react_1.default.createElement(aurum_1.Table, null,
                react_1.default.createElement("thead", null,
                    react_1.default.createElement("tr", null,
                        react_1.default.createElement("th", null, t('EntityAttribute.id')),
                        react_1.default.createElement("th", null, "Name"),
                        react_1.default.createElement("th", null, t('OwnerConditions.commissionFee')))),
                react_1.default.createElement("tbody", null, (_c = (_b = Object.entries(ownerFeatureConditions)) === null || _b === void 0 ? void 0 : _b.map) === null || _c === void 0 ? void 0 : _c.call(_b, function (_a) {
                    var _b, _c;
                    var _d = tslib_1.__read(_a, 2), id = _d[0], ele = _d[1];
                    return react_1.default.createElement("tr", { key: id },
                        react_1.default.createElement("td", null, id),
                        react_1.default.createElement("td", null, ele.featureName),
                        react_1.default.createElement("td", null,
                            react_1.default.createElement(aurum_1.Input, { id: id, type: 'number', min: 0, max: 100, step: 0.01, placeholder: ownerCondition === null || ownerCondition === void 0 ? void 0 : ownerCondition.commissionFee, value: (_c = (_b = ele === null || ele === void 0 ? void 0 : ele.ownerFeatureCondition) === null || _b === void 0 ? void 0 : _b.commissionFee) !== null && _c !== void 0 ? _c : '', onChange: function (event) {
                                    var newEntry = new OfcEntry(ele.featureName, ele.ownerFeatureCondition); // altes Objekt wird kopiert
                                    newEntry.ownerFeatureCondition.commissionFee = event.target.value.length == 0 ? undefined : event.target.value;
                                    var newOwnerFeatureConditions = tslib_1.__assign({}, ownerFeatureConditions);
                                    newOwnerFeatureConditions[id] = newEntry;
                                    setOwnerFeatureConditions(newOwnerFeatureConditions);
                                } })));
                })))
            : react_1.default.createElement("div", null,
                react_1.default.createElement("p", null, "Dem Partner sind keine Features hinterlegt."));
    };
    // const recalculateOwnerPayments = async () => {
    //   setSaveBtnDisabled(true);
    //   setActionLabel(null);
    //   try {
    //     await OwnerCondition.recalculatePayments(selectedOwner);
    //     setActionLabel(t('OwnerConditions.recalculate.done'));
    //   } catch (e) {
    //     setActionLabel(t('Controls.error.processing'));
    //   }
    //   setSaveBtnDisabled(false);
    // };
    // Speichert die comissionFee des Features im Modal
    var saveOwnerConditions = function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
        var ownerFeatureConditionList, oc, r, e_4;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSaveBtnDisabled(true);
                    setActionLabel(null);
                    ownerFeatureConditionList = [];
                    Object.values(ownerFeatureConditions).forEach(function (ofc) {
                        if (!(ofc.ownerFeatureCondition.commissionFee == null ||
                            ofc.ownerFeatureCondition.commissionFee == ownerCondition.commissionFee)) {
                            ownerFeatureConditionList.push(ofc.ownerFeatureCondition);
                        }
                    });
                    oc = tslib_1.__assign(tslib_1.__assign({}, ownerCondition), { featureConditions: ownerFeatureConditionList });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, js_1.OwnerCondition.save(oc, { timeout: 120000 })];
                case 2:
                    r = _a.sent();
                    setOwnerCondition(r);
                    setActionLabel(t('Controls.save.done'));
                    return [3 /*break*/, 4];
                case 3:
                    e_4 = _a.sent();
                    setActionLabel(t('Controls.error.processing'));
                    return [3 /*break*/, 4];
                case 4:
                    setSaveBtnDisabled(false);
                    return [2 /*return*/];
            }
        });
    }); };
    // Zeigt eine Ladezeile mit übergebenen Text an
    var getProgressbar = function (text) {
        return react_1.default.createElement(aurum_1.Progress, { className: 'table' }, t(text));
    };
    var modalOwnerCondition = null;
    if (showModalOwnerCondition) {
        modalOwnerCondition = react_1.default.createElement(aurum_1.Modal, { className: 'ownerConditionModal', onClickBackdrop: function () {
                setShowModalOwnerCondition(false);
            } },
            react_1.default.createElement(aurum_1.Header, null,
                react_1.default.createElement("h2", null, t('OwnerConditions.featureConditions'))),
            react_1.default.createElement(aurum_1.Body, null, loadingFeatureByOwner
                ? getProgressbar('BookingList.loading')
                : showOwnerFeatureConditions()),
            react_1.default.createElement(aurum_1.Footer, null,
                react_1.default.createElement("p", null, ((_b = Object.entries(ownerFeatureConditions)) === null || _b === void 0 ? void 0 : _b.length) != 0 ? 'Angaben werden bei Änderung direkt übernommen.' : ''),
                react_1.default.createElement("span", null),
                react_1.default.createElement(aurum_1.Button, { type: 'primary', disabled: (ownerFeatureConditions === null || ownerFeatureConditions === void 0 ? void 0 : ownerFeatureConditions.size) > 0, onClick: function () {
                        setShowModalOwnerCondition(false);
                    } }, t('Controls.close'))));
    }
    var modalPhoneValidationFailed = null;
    if (modalPhoneData != null) {
        modalPhoneValidationFailed = react_1.default.createElement(aurum_1.Modal, { className: 'phoneValidationFailedModal', onClickBackdrop: function () {
                setModalPhoneData(null);
            } },
            react_1.default.createElement(aurum_1.Header, null,
                react_1.default.createElement("h2", null, t('Error.reason.VALIDATION_FAILED'))),
            react_1.default.createElement(aurum_1.Body, null,
                react_1.default.createElement("div", null,
                    react_1.default.createElement("p", null, modalPhoneData === null || modalPhoneData === void 0 ? void 0 : modalPhoneData.ErrorMessage),
                    react_1.default.createElement("p", null,
                        t('Edit.errorModal.reason'),
                        ":"), (_c = modalPhoneData === null || modalPhoneData === void 0 ? void 0 : modalPhoneData.ErrorDetail) === null || _c === void 0 ? void 0 :
                    _c.map(function (eArr) { return react_1.default.createElement("p", null,
                        "- ",
                        eArr); }))),
            react_1.default.createElement(aurum_1.Footer, null,
                react_1.default.createElement("span", null),
                react_1.default.createElement(aurum_1.Button, { type: 'primary', disabled: (ownerFeatureConditions === null || ownerFeatureConditions === void 0 ? void 0 : ownerFeatureConditions.size) > 0, onClick: function () {
                        setModalPhoneData(null);
                    } }, t('Controls.close'))));
    }
    // Gibt das Dropdown für die Owner zurück
    var getSelectbarOwner = function () {
        var _a;
        return react_1.default.createElement(aurum_1.Select, { value: selectedOwner, native: false, label: 'Partner', 
            // setzt die Einträge in die Liste
            options: (_a = owners === null || owners === void 0 ? void 0 : owners.map) === null || _a === void 0 ? void 0 : _a.call(owners, function (owner) {
                return { id: owner.id, label: owner.companyName };
            }), onChange: function (onChangeEvent) {
                setSelectedOwner(onChangeEvent.target.value);
            } });
    };
    function showActionLabel() {
        return react_1.default.createElement("p", null, actionLabel !== null && actionLabel !== void 0 ? actionLabel : '');
    }
    // Zeige Konditionen an
    var showConditions = function () {
        var _a, _b;
        return react_1.default.createElement("div", null,
            react_1.default.createElement(aurum_1.Row, null,
                react_1.default.createElement("p", null, t('OwnerConditions.commissionFee')),
                react_1.default.createElement(aurum_1.Input, { type: 'number', min: 0, max: 100, step: 0.01, placeholder: t('OwnerConditions.commissionFee'), value: (_a = ownerCondition === null || ownerCondition === void 0 ? void 0 : ownerCondition.commissionFee) !== null && _a !== void 0 ? _a : '', onChange: function (event) {
                        var newOwnerCondition = tslib_1.__assign({}, ownerCondition); // altes Objekt wird kopiert
                        newOwnerCondition.commissionFee = event.target.value;
                        setOwnerCondition(newOwnerCondition);
                    } })),
            react_1.default.createElement(aurum_1.Row, null,
                react_1.default.createElement("p", null, t('OwnerConditions.commissionFeeCalculation')),
                react_1.default.createElement(aurum_1.Select, { value: ownerCondition.commissionFeeCalculation, native: false, label: t('OwnerConditions.commissionFeeCalculation'), options: (_b = commissionFeeCalculations === null || commissionFeeCalculations === void 0 ? void 0 : commissionFeeCalculations.map) === null || _b === void 0 ? void 0 : _b.call(commissionFeeCalculations, function (ele) {
                        return { id: ele, label: t('Enum.' + ele) };
                    }), onChange: function (onChangeEvent) {
                        setOwnerCondition(tslib_1.__assign(tslib_1.__assign({}, ownerCondition), { commissionFeeCalculation: onChangeEvent.target.value }));
                    } })),
            react_1.default.createElement(aurum_1.Row, null,
                react_1.default.createElement("p", null, t('OwnerConditions.featureConditions')),
                react_1.default.createElement(aurum_1.Button, { onClick: function () {
                        loadFeatureForOwner(selectedOwner);
                        setShowModalOwnerCondition(true);
                    } }, "Anzeigen & Bearbeiten")),
            showActionLabel(),
            react_1.default.createElement(aurum_1.Row, { columns: 6 },
                react_1.default.createElement(aurum_1.Button, { type: 'primary', disabled: saveBtnDisabled, onClick: function () {
                        saveOwnerConditions();
                    } }, t(['ownerConditionModal.save', 'Controls.save']))),
            react_1.default.createElement("h2", null, "Einstellungen Provisionen"),
            react_1.default.createElement(aurum_1.InputGroup, null,
                react_1.default.createElement(aurum_1.Input, { type: 'checkbox', label: 'keinen Steuern auf Gebühren', value: ownerCondition === null || ownerCondition === void 0 ? void 0 : ownerCondition.noPaymentSplitFeeTax, onChange: function (e) {
                        var newOwnerCondition = tslib_1.__assign({}, ownerCondition); // altes Objekt wird kopiert
                        newOwnerCondition.noPaymentSplitFeeTax = e.target.checked;
                        setOwnerCondition(newOwnerCondition);
                    } }),
                react_1.default.createElement(aurum_1.Input, { type: 'checkbox', label: 'Provisionen beinhalten bereits Steuern', value: ownerCondition === null || ownerCondition === void 0 ? void 0 : ownerCondition.paymentSplitFeeAlreadyContainsTaxes, onChange: function (e) {
                        var newOwnerCondition = tslib_1.__assign({}, ownerCondition); // altes Objekt wird kopiert
                        newOwnerCondition.paymentSplitFeeAlreadyContainsTaxes = e.target.checked;
                        setOwnerCondition(newOwnerCondition);
                    } })));
    };
    // wird aufgerufen, wenn keine Konditionen geladen werden können
    var failCondition = function () {
        var _a;
        return react_1.default.createElement("div", null,
            react_1.default.createElement("p", null,
                "Beim Laden der Konditionen des Partners ", (_a = owners === null || owners === void 0 ? void 0 : owners.find(function (o) { return o.id === selectedOwner; })) === null || _a === void 0 ? void 0 :
                _a.companyName,
                " kam es zu einem Fehler. Bitte nehmen Sie Kontakt mit MDSI IT Solutions GmbH auf."));
    };
    // wenn laden aktiv, dann zeige Ladebalken, ansonsten wird das Dropdown angezeigt
    return react_1.default.createElement("div", { className: 'OwnerConditionMerchant' },
        modalOwnerCondition,
        modalPhoneValidationFailed,
        react_1.default.createElement(aurum_1.Sheet, null,
            react_1.default.createElement("h2", null, t('OwnerConditions.Owner')),
            loadingOwner
                ? getProgressbar('BookingList.loading')
                : getSelectbarOwner(),
            react_1.default.createElement("h2", null, t('OwnerConditions')),
            loadingOwnerConditions && ownerCondition == null
                ? getProgressbar('BookingList.loading')
                : (ownerCondition == null
                    ? failCondition()
                    : showConditions())));
};
// notwendig, damit die Datei importiert werden kann
exports.default = (0, react_1.memo)(OwnerConditionMerchant);
